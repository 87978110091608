import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import TimeAgoWithTooltip from "Components/TimeAgoWithTooltip";
import Pronoun from "Components/Pronoun";
import Avatar from "Components/Avatar";
import "./StudentInfo.scss";
import { getAvatarUrl } from "util/avatar";
import { generateProfileUrl } from "util/user";
import { useAppContext } from "Context/AppProvider";
import Bowser from "bowser";

const getUniqueTags = (tags = [], student = {}) => {
  const allTags = [...(student.tags || []), ...tags];
  const uniqueTagsMap = new Map(allTags.map(tag => [tag.name, tag]));
  return [...uniqueTagsMap.values()];
};

const Title = ({ student, isLead, nonLearner, profileLink }) => {
  const { fullName, pronoun } = student;
  const titleContent = (
    <>
      {fullName} <Pronoun pronoun={pronoun} /> {isLead ? "(lead)" : ""}
    </>
  );

  return (
    <div className="title">
      {nonLearner || !profileLink ? titleContent : <Link to={profileLink}>{titleContent}</Link>}
    </div>
  );
};

const StudentInfo = ({
  student,
  when,
  showDetails,
  hideTags,
  details,
  tags,
  is_lead,
  nonLearner,
  excludeStudentTags,
  platform = null,
}) => {
  const { currentUser } = useAppContext();
  const profileLink = generateProfileUrl({ currentUser, studentUid: student.uid });
  const avatarUrl = student.avatarUrl || getAvatarUrl(student.email || student.socials?.email);
  const detailsToInclude = ["program_name"];

  const badges = Object.entries({ ...details, ...student.info })
    .filter(([name]) => detailsToInclude.includes(name))
    .map(([name, info]) => (
      <Badge key={name} className="info">
        {info}
      </Badge>
    ));
  if (platform) {
    const browser = Bowser.getParser(platform);
    const os = browser.getOS();

    badges.push(
      <Badge key="platform" bg="light">
        {os?.name}
      </Badge>
    );
  }

  if (platform) {
    const browser = Bowser.getParser(platform);
    const os = browser.getOS();

    badges.push(
      <Badge key="platform" className="info">
        {os?.name}
      </Badge>
    );
  }

  if (!hideTags) {
    const uniqueTags = getUniqueTags(tags, excludeStudentTags ? {} : student);
    uniqueTags.forEach(tag => {
      const background = tag.settings?.badge?.background || "warning";
      const text = tag.settings?.badge?.text || "dark";
      badges.push(
        <OverlayTrigger
          key={`tagbadge-${tag.name}`}
          placement="bottom"
          overlay={<Tooltip>{tag.queueNote || tag.name}</Tooltip>}
        >
          <Badge className={`ml-1 rudder-tag bg-${background} text-${text}`}>{tag.name}</Badge>
        </OverlayTrigger>
      );
    });
  }

  return (
    <div className="student-info-container">
      <Avatar
        to={profileLink}
        src={avatarUrl}
        busy={student.busy}
        onDuty={student.onDuty}
        name={student.fullName}
      />
      <div className="student-info">
        <div className="name">
          <Title
            student={student}
            isLead={is_lead}
            nonLearner={nonLearner}
            profileLink={profileLink}
          />
        </div>
        {showDetails && (
          <div className="student-details-container">
            <div className="details">{badges}</div>
            {!nonLearner && (
              <>{when ? <TimeAgoWithTooltip when={when} /> : <small>Never Helped</small>}</>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentInfo;
