import PendingAssistanceRequest from "./PendingAssistanceRequest";
import ListGroup from "Components/ListGroup";
import "./OpenRequestsList.scss";
import { useAppContext } from "Context/AppProvider";
import ReviewMessage from "./ReviewMessage";

const OpenRequestsList = ({ assistanceRequests, currentUser }) => {
  const {
    tasksHandler: { needsReview, allOpenTasksForUser },
    settingsHandler: { settings },
  } = useAppContext();

  const userRequests = assistanceRequests.filter(ar => ar.type === "UserRequest");
  const mentorSkip = settings?.featureFlags?.mentorSkip;
  const mentorNeedsReview = settings?.featureFlags?.mentorNeedsReview && needsReview().length > 0;

  if (!currentUser.onDuty) {
    return (
      <ListGroup icon={userRequests.length} title="Assigned Requests" className="assigned-requests">
        <div className="off-duty-alert">
          <strong>You must be on duty to be assigned a queue!</strong>
        </div>
      </ListGroup>
    );
  }

  if (settings?.featureFlags?.sortAssignedRequestsByNewest) {
    userRequests.sort((a, b) => Date.parse(b.startAt) - Date.parse(a.startAt));
  }

  const openTasks = allOpenTasksForUser();
  const assistanceRequestsNotSkipped = userRequests
    .map(ar => ({
      ar,
      task: openTasks.find(task => task.assistanceRequest.id === ar.id && !task.skippedAt),
    }))
    .filter(({ task }) => !!task)
    .sort((a, b) => new Date(a.task.createdAt) - new Date(b.task.createdAt))
    .map(({ ar }) => ar);

  const renderReviewMessage = () => <ReviewMessage />;

  const renderAssistanceRequestsNotSkipped = () => {
    const visibleAssignedRequests = 3;

    if (assistanceRequestsNotSkipped?.length > 0) {
      return assistanceRequestsNotSkipped
        .slice(0, visibleAssignedRequests)
        .map(request => <PendingAssistanceRequest key={request.id} assistanceRequest={request} />);
    }
    return null;
  };

  const renderAssistanceRequests = () =>
    userRequests.map(assistanceRequest => (
      <PendingAssistanceRequest key={assistanceRequest.id} assistanceRequest={assistanceRequest} />
    ));

  const renderListContent = () => {
    if (mentorNeedsReview) return renderReviewMessage();
    if (mentorSkip) return renderAssistanceRequestsNotSkipped();
    return renderAssistanceRequests();
  };

  return (
    <ListGroup icon={userRequests.length} title="Assigned Requests" className="assigned-requests">
      {renderListContent()}
    </ListGroup>
  );
};

export default OpenRequestsList;
