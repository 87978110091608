import { useState, useEffect } from "react";
import AssistanceActions from "./AssistanceActions";
import QueueItem from "Components/QueueItem";
import StudentInfo from "Components/StudentInfo";
import TeacherInfo from "Components/TeacherInfo";
import RequestDetails from "Components/RequestDetails";
import AssistanceReviewModal from "Components/AssistanceReviewForm/AssistanceReviewModal";
import AddStudentsModal from "Components/AddStudentsModal";
import ShowRequestDetailsModal from "./ShowRequestDetailsModal";
import ClaimContextModal from "./ClaimContextModal";
import requests from "Requests";
import { finishTransaction } from "util/instrumentation";
import { useAppContext } from "Context/AppProvider";
import FormResults from "./FormResults";
import IFrameModal from "./IFrameModal";
import CalendlyEvent from "./CalendlyEvent";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "constants/toast";
import { confirm } from "Components/Confirmation";
import Button from "Components/Buttons/AssistanceButtons";
import TaskMasterInfo from "./TaskMasterInfo";
import TaskReviewModal from "./TaskReviewModal";

// eslint-disable-next-line complexity
// eslint-disable-next-line max-statements
const Assistance = ({ admin, task }) => {
  const {
    queueChannel,
    currentUser,
    tasksHandler,
    settingsHandler: { settings },
  } = useAppContext();

  const { assistanceRequest, assistor, assistance } = task;
  const { externalFeedbackUrl } = assistance;
  const { id: assistanceRequestId, request, requestor: student, type } = assistanceRequest;
  const { finishResourceUrl } = request;
  const externalUrl = externalFeedbackUrl ? new URL(externalFeedbackUrl) : undefined;
  const isTaskRequest = type === "TaskRequest";
  const isUserRequest = type === "UserRequest";
  const isMine = assistor?.uid === currentUser.uid;
  const isClaimed = task.state === "claimed";
  const inProgress = task.state === "in_progress";
  const inReview = task.state === "needs_review";
  const inTaskReview = task.state === "needs_task_review";
  const showClaimContext =
    settings.featureFlags?.claimContext && isClaimed && isUserRequest && isMine;

  const [buttonsDisabled, setDisabled] = useState(false);
  const [showClaimContextModal, setShowClaimContextModal] = useState(showClaimContext);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showTaskReviewModal, setShowTaskReviewModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const [showReviewModalNumber, setShowReviewModalNumber] = useState(0);
  const [formsCompleted, setFormsCompleted] = useState({});
  const [showInitialReviewProcessModal, setShowInitialReviewProcessModal] = useState(false);
  const [addedStudents, setaddedStudents] = useState([]);

  externalUrl?.searchParams?.set("providerUid", currentUser.uid);

  useEffect(() => {
    if (isClaimed) finishTransaction("e2e-claimAR", assistanceRequestId);
  }, [isClaimed, assistanceRequestId]);

  useEffect(() => {
    if (!inProgress) return;

    setDisabled(false);
  }, [inProgress, buttonsDisabled]);

  const handleStartAssisting = () => {
    if (finishResourceUrl) return window.open(finishResourceUrl);

    setDisabled(true);
    queueChannel.startAssistance(assistanceRequest).catch(err => {
      setDisabled(false);
      toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
    });
    tasksHandler.startAssistance(assistanceRequest.id);
  };

  const handleCancelAssisting = async () => {
    const confirmed = await confirm("Return this request to unclaimed?", "Yes", "No");

    if (confirmed) {
      setDisabled(true);
      queueChannel.cancelAssistance(assistanceRequest).catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
    }
  };

  const handleCloseAssistance = async () => {
    const confirmed = await confirm(
      "Are you sure you would like to close this request?",
      "Yes",
      "No"
    );

    if (confirmed) {
      setDisabled(true);
      queueChannel.closeAssistance(assistanceRequest.id).catch(err => {
        setDisabled(false);
        toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
      });
    }
  };

  const handleEndAssisting = async () => {
    if (!inReview && !inTaskReview) {
      const confirmed = await confirm(
        "Are you sure you want to finish this assistance?",
        "Finish",
        "Go Back"
      );

      if (confirmed) {
        setDisabled(true);
        queueChannel
          .finishAssistance({
            id: assistanceRequest.id,
            assistance_id: assistance.id,
            request_id: assistanceRequest.id,
          })
          .then(() => !isTaskRequest && setShowInitialReviewProcessModal(true))
          .catch(err => {
            setDisabled(false);
            toast.error(err.response?.data?.error, TOAST_OPTIONS.error);
          });
      }
    }

    if (inReview) setShowInitialReviewProcessModal(true);
    if (inTaskReview) setShowTaskReviewModal(true);
  };

  const prevForm = formInfo => {
    setFormsCompleted({ ...formsCompleted, [formInfo.requestId]: formInfo });
    setShowReviewModalNumber(showReviewModalNumber - 1);
  };

  const nextForm = formInfo => {
    setFormsCompleted({ ...formsCompleted, [formInfo.requestId]: formInfo });
    setShowReviewModalNumber(showReviewModalNumber + 1);

    return { ...formsCompleted, [formInfo.request_id]: formInfo };
  };

  const completeReview = formInfo => {
    const completedFeedback = nextForm(formInfo);
    queueChannel.reviewAssistance(completedFeedback);
  };

  const closeReviewModal = () => {
    setFormsCompleted({});
    setShowReviewModalNumber(0);
    setShowReviewModal(false);
    setShowTaskReviewModal(false);
  };

  const addStudents = students => {
    const studentUIDs = students.map(s => s.uid);
    return requests
      .appendStudentsToAR({
        parent_id: assistanceRequest.id,
        student_uids: studentUIDs,
      })
      .then(data => {
        const { assistanceRequests: addedStudents } = data;
        setaddedStudents([...addedStudents]);
        startReview();
      });
  };

  const startReview = () => {
    setShowInitialReviewProcessModal(false);
    setShowReviewModal(true);
  };

  const taskARs = [assistanceRequest, ...addedStudents];
  const reviewTaskAR = taskARs[showReviewModalNumber];

  return (
    <QueueItem
      label={request?.resourceType || request?.taskType}
      type={isTaskRequest ? "Task" : "Assistance"}
      disabled={buttonsDisabled}
      standOut={isMine}
      thickDividers={settings.featureFlags?.thickQueueDividers}
    >
      {student && (
        <StudentInfo
          student={student}
          showDetails={true}
          when={assistanceRequest.createdAt}
          tags={assistanceRequest.tags}
          is_lead={taskARs.length > 1 && !assistanceRequest.parent_id}
          excludeStudentTags
          platform={request?.info?.platform}
        />
      )}
      {isTaskRequest && (
        <TaskMasterInfo
          tags={assistanceRequest.tags}
          when={assistanceRequest.createdAt}
          taskType={assistanceRequest.request.taskType}
        />
      )}
      {addedStudents.map(ar => (
        <StudentInfo
          key={ar.requestor.uid}
          student={ar.requestor}
          showDetails={false}
          when={ar.startAt}
          tags={ar.tags}
          is_lead={false}
        />
      ))}
      {task.calendlyEvent && <CalendlyEvent {...task.calendlyEvent} />}

      {assistanceRequest.formResults ? (
        <FormResults
          formName={assistanceRequest.formResults?.form_name}
          results={assistanceRequest.formResults?.results}
        />
      ) : (
        <div className="blurb">
          <RequestDetails
            requestedBy={admin && request.requestedBy}
            categoryName={request.categoryName}
            day={request.info && request.info.day}
            activityUrl={request.resourceLink || request.link}
            activityTitle={request.resourceName}
            reason={request.reason}
            resubmission={request.resubmission}
          />
          {request.requestCode && (
            <Button.OpenModal
              type="code"
              onClick={() => setShowCodeModal(true)}
              disabled={buttonsDisabled}
            />
          )}
          {request.requestErrorMessage && (
            <Button.OpenModal
              type="error"
              onClick={() => setShowErrorMessageModal(true)}
              disabled={buttonsDisabled}
            />
          )}
          {assistor && <TeacherInfo assistor={assistor} when={assistance.startAt} />}
        </div>
      )}

      <AssistanceActions
        disabled={buttonsDisabled}
        assistanceRequest={assistanceRequest}
        assistance={assistance}
        type={type}
        finishResourceUrl={finishResourceUrl || request.link}
        admin={admin}
        isMine={isMine}
        state={task.state}
        handleStartAssisting={handleStartAssisting}
        handleEndAssisting={handleEndAssisting}
        handleCancelAssisting={handleCancelAssisting}
        handleCloseAssistance={handleCloseAssistance}
      />

      {showInitialReviewProcessModal &&
        (externalUrl ? (
          <IFrameModal
            src={externalUrl.href}
            hide={() => setShowInitialReviewProcessModal(false)}
            height="480px"
          />
        ) : (
          <AddStudentsModal
            taskARs={taskARs}
            addStudents={addStudents}
            skip={startReview}
            hide={() => setShowInitialReviewProcessModal(false)}
          />
        ))}
      {showReviewModal && reviewTaskAR && (
        <AssistanceReviewModal
          key={reviewTaskAR.id}
          assistanceRequest={reviewTaskAR}
          task={task}
          hide={closeReviewModal}
          nextForm={showReviewModalNumber < taskARs.length - 1 ? nextForm : completeReview}
          multipleStudents={!!addedStudents.length}
          isLast={showReviewModalNumber === taskARs.length - 1}
          backFn={showReviewModalNumber !== 0 && prevForm}
          answers={formsCompleted}
          numberOfStudents={taskARs.length}
          index={showReviewModalNumber}
        />
      )}
      {showTaskReviewModal && (
        <TaskReviewModal
          key={task.id}
          assistanceRequest={task.assistanceRequest}
          task={task}
          hide={closeReviewModal}
          nextForm={completeReview}
          answers={formsCompleted}
        />
      )}
      {showCodeModal && (
        <ShowRequestDetailsModal
          type="code"
          requestDetails={request.requestCode}
          setShowModal={setShowCodeModal}
        />
      )}
      {showErrorMessageModal && (
        <ShowRequestDetailsModal
          type="error"
          requestDetails={request.requestErrorMessage}
          setShowModal={setShowErrorMessageModal}
        />
      )}
      {showClaimContextModal && (
        <ClaimContextModal
          assistanceRequest={assistanceRequest}
          cancelClaim={handleCancelAssisting}
          setShowModal={setShowClaimContextModal}
        />
      )}
    </QueueItem>
  );
};

export default Assistance;
