import { useState } from "react";
import useSettings from "Hooks/useSettings";
import { marked } from "marked";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import GithubButton from "Screens/sign-in/SignIn/GithubButton";
import StudentsList from "Components/StudentsList";
import MentorDutyButton from "Components/MentorDutyButton";
import ExternalUserAuth from "./ExternalUserAuth";
import PendingEvaluationsList from "Screens/queue/Queue/PendingEvaluationsList";
import AdminPendingEvaluationsList from "Screens/admin/Queue/PendingEvaluationsList";
import MentorStatusButton from "Components/MentorStatusButton";
import FeedbackModal from "Screens/feedback/Feedbacks/FeedbackModal";
import { DASHBOARD_NOTE, EXTENSION_DASHBOARD_NOTE } from "constants/localStorage";
import { isBrowserExtension } from "util/env";

export const Flags = {
  // UI
  ADMIN_SIGN_IN: "ui.adminSignIn",
  CANCEL_BUTTON: "featureFlags.cancelButton",
  CUSTOM_SIGN_IN: "ui.signIn",
  DASHBOARD_NOTE: "ui.dashboardNote",
  EXTENSION_DASHBOARD_NOTE: "ui.extensionDashboardNote",
  HIDE_FOOTER: "ui.footer.display",
  HOME_BUTTON: "ui.displayHomeButton",
  MENTORS_LIST: "ui.displayMentorsList",
  MENTOR_QUEUE_NOTE: "ui.mentorQueueNote",
  NOTIFICATIONS_BANNER_MESSAGE: "ui.notificationsBannerMessage",
  OPEN_REQUESTS: "featureFlags.openRequests",
  OPEN_REQUESTS_ADMIN: "featureFlags.openRequestsAdmin",
  QUEUE_WAIT_TIME_UI: "ui.queue_wait_time",
  REMOVE_BUTTON: "featureFlags.removeButton",
  REQUEST_MODAL_TITLE: "ui.assistanceRequestModalTitle",
  STUDENT_LIST: "featureFlags.studentList",
  STUDENT_LIST_ADMIN: "featureFlags.studentListAdmin",
  ZOOM_AUTHORIZATION_NOTE: "ui.zoomAuthorizationNote",
  // FEATURES
  ADMIN_QUEUE_FILTER: "features.adminQueueFilter",
  ADMIN_ON_DUTY: "features.adminOnDuty",
  ASYNC_ASSISTANCE_REQUESTS: "features.asyncAssistanceRequests",
  DELEGATE_REQUEST_BUTTON: "featureFlags.delegateRequests",
  DISCORD_AUTH_REQUIRED: "features.discord_auth_required",
  LLM_BOT: "features.llmBot",
  NOTIFY_ED_TEAM_FLAG: "features.notifyEdTeamFlag",
  ZOOM_SUPPORT: "features.zoomOauthSupport",
  AUTO_CONFERENCES: "features.autoConferences",
  CHAT_ASSISTANCE: "features.chatAssistance",
  SUGGEST_ASSISTANCES: "features.suggestAssistances",
  SUGGEST_SOLVED_ASSISTANCES: "features.suggestSolvedAssistances",
  SECONDARY_TASK_QUEUE: "features.secondaryTaskQueue",
  // ETC
  EXTERNAL_FEEDBACK_FROM_LEARNER_URL: "externalFeedbackFromLearnerUrl",
};

// eslint-disable-next-line complexity
const SettingsConditional = ({ flag, deploymentId, children, ...props }) => {
  const defaultNoteState = localStorage.getItem(DASHBOARD_NOTE);
  const defaultExtensionNoteState = localStorage.getItem(EXTENSION_DASHBOARD_NOTE);
  const [hideNote, setHideNote] = useState(defaultNoteState);
  const [hideExtensionNote, setHideExtensionNote] = useState(defaultExtensionNoteState);

  // If deploymentId is undefined, the following uses deployment settings associated with the current user - Harry
  const { settings } = useSettings({ deploymentId });

  if (!settings) return null;

  const renderChildren = () => (typeof children === "function" ? children(settings) : children);

  if (flag === Flags.QUEUE_WAIT_TIME_UI && settings.ui?.queueWaitTime) {
    return renderChildren();
  }

  if (flag === Flags.HIDE_FOOTER && !!settings.ui?.footer?.display) {
    return renderChildren();
  }

  if (flag === Flags.STUDENT_LIST && !!settings.featureFlags?.studentList) {
    return (
      <StudentsList displayByDefault={settings.featureFlags?.studentListByDefault} {...props} />
    );
  }

  if (flag === Flags.STUDENT_LIST_ADMIN && !!settings.featureFlags?.studentListAdmin) {
    return (
      <StudentsList displayByDefault={settings.featureFlags?.studentListByDefault} {...props} />
    );
  }

  if (flag === Flags.OPEN_REQUESTS && !!settings.featureFlags?.openRequests) {
    return <PendingEvaluationsList {...props} />;
  }

  if (flag === Flags.OPEN_REQUESTS_ADMIN && !!settings.featureFlags?.openRequestsAdmin) {
    return <AdminPendingEvaluationsList {...props} />;
  }

  if (flag === Flags.MENTORS_LIST && !!settings.ui?.displayMentorsList) {
    return <MentorStatusButton {...props} />;
  }

  if (flag === Flags.NOTIFY_ED_TEAM_FLAG && !!settings.features?.notifyEdTeamFlag) {
    return renderChildren();
  }

  const handleNotice = () => {
    setHideNote(state => !state);
    localStorage.setItem(DASHBOARD_NOTE, true);
  };

  const handleExtensionNotice = () => {
    setHideExtensionNote(state => !state);
    localStorage.setItem(EXTENSION_DASHBOARD_NOTE, true);
  };

  if (
    flag === Flags.DASHBOARD_NOTE &&
    !!settings.ui?.dashboardNote &&
    !hideNote &&
    !isBrowserExtension()
  ) {
    return (
      <div className="alert alert-info">
        <button className="close" onClick={handleNotice} type="button">
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <p
          dangerouslySetInnerHTML={{
            __html: marked(settings.ui.dashboardNote),
          }}
        />
      </div>
    );
  }

  if (
    flag === Flags.DASHBOARD_NOTE &&
    !!settings.ui?.extensionDashboardNote &&
    !hideExtensionNote &&
    isBrowserExtension()
  ) {
    return (
      <div className="alert alert-info">
        <button className="close" onClick={handleExtensionNotice} type="button">
          <span aria-hidden="true">&times;</span>
          <span className="sr-only">Close</span>
        </button>
        <p
          dangerouslySetInnerHTML={{
            __html: marked(settings.ui.extensionDashboardNote),
          }}
        />
      </div>
    );
  }

  if (flag === Flags.MENTOR_QUEUE_NOTE && !!settings.ui?.mentorQueueNote) {
    return (
      <div className="alert alert-info">
        <p
          dangerouslySetInnerHTML={{
            __html: marked(settings.ui.mentorQueueNote),
          }}
        />
      </div>
    );
  }

  if (
    flag === Flags.ZOOM_SUPPORT &&
    (!!settings.features?.zoomOauthSupport || !!settings.features?.chat?.assistanceZoomChannels)
  ) {
    return renderChildren();
  }

  if (flag === Flags.NOTIFY_ED_TEAM_FLAG && !!settings.features?.notifyEdTeamFlag) {
    return renderChildren();
  }

  if (flag === Flags.NOTIFICATIONS_BANNER_MESSAGE && !!settings.ui?.notificationsBannerMessage) {
    return renderChildren();
  }

  if (flag === Flags.ZOOM_AUTHORIZATION_NOTE && !!settings.ui?.zoomAuthorizationNote) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: marked(settings.ui?.zoomAuthorizationNote),
        }}
      />
    );
  }

  if (flag === Flags.ADMIN_SIGN_IN) {
    return <GithubButton message="Admins may log in via Github" />;
  }

  if (flag === Flags.CUSTOM_SIGN_IN) {
    return settings.ui?.signIn?.link ? (
      <>
        <Button as="a" className="login-button" href={settings.ui.signIn.link}>
          {settings.ui.signIn.buttonText}
        </Button>
        <small
          dangerouslySetInnerHTML={{
            __html: marked(settings.ui.signIn.message),
          }}
        />
      </>
    ) : (
      <GithubButton
        message={settings.ui?.signIn?.message || "A Github account is required to log into Rudder"}
        buttonText={settings.ui?.signIn?.buttonText}
      />
    );
  }

  if (flag === Flags.REQUEST_MODAL_TITLE) {
    return <>{settings.ui?.assistanceRequestModalTitle || "Request Assistance"}</>;
  }

  if (flag === Flags.HOME_BUTTON && !!settings.ui?.displayHomeButton) {
    return (
      <Link to="/" className="btn home-btn">
        <i className="fa fa-fw fa-house" />
      </Link>
    );
  }

  if (flag === Flags.REMOVE_BUTTON && !!settings.featureFlags?.removeButton) {
    return renderChildren();
  }

  if (flag === Flags.ADMIN_ON_DUTY && !!settings.featureFlags?.adminOnDuty) {
    return <MentorDutyButton {...props} />;
  }

  if (
    flag === Flags.EXTERNAL_FEEDBACK_FROM_LEARNER_URL &&
    !settings.externalFeedbackFromLearnerUrl
  ) {
    return <FeedbackModal {...props} />;
  }

  if (flag === Flags.DELEGATE_REQUEST_BUTTON && !!settings.featureFlags?.delegateRequests) {
    return renderChildren();
  }

  if (flag === Flags.DISCORD_AUTH_REQUIRED) {
    return settings.features?.requireDiscordAuth ? (
      <ExternalUserAuth platform="Discord">{renderChildren()}</ExternalUserAuth>
    ) : (
      renderChildren()
    );
  }

  if (flag === Flags.LLM_BOT && !!settings.features?.llmBot) {
    return renderChildren();
  }

  if (
    flag === Flags.AUTO_CONFERENCES &&
    (!!settings.features?.autoConferences || !!settings.features?.chatAssistance)
  ) {
    return renderChildren();
  }

  if (flag === Flags.CHAT_ASSISTANCE && !!settings.features?.chatAssistance) {
    return renderChildren();
  }

  if (flag === Flags.SUGGEST_ASSISTANCES && !!settings.features?.suggestAssistances) {
    return renderChildren();
  }

  if (flag === Flags.SUGGEST_SOLVED_ASSISTANCES && !!settings.features?.suggestSolvedAssistances) {
    return renderChildren();
  }

  if (flag === Flags.SECONDARY_TASK_QUEUE && !!settings.featureFlags?.secondaryTaskQueue) {
    return renderChildren();
  }

  if (flag === Flags.ADMIN_QUEUE_FILTER && !!settings.featureFlags?.adminQueueFilters) {
    return renderChildren();
  }

  return null;
};

Object.assign(SettingsConditional, Flags);

export default SettingsConditional;
